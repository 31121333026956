@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  touch-action: pan-x pan-y;
  -ms-touch-action: manipulation;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* globals.css or a specific CSS module */
@keyframes marquee {
  0% {
    transform: translateX(
      100%
    ); /* Start from outside the container on the right */
  }
  100% {
    transform: translateX(-100%); /* Move all the way to the left */
  }
}

.partner-marquee {
  position: relative;
  overflow: hidden; /* Hide the overflow content */
  width: 100%; /* Full width */
  height: 6.85vh; /* Adjust height as needed */
}

.inner {
  display: flex; /* Align logos in a row */
  animation: marquee 20s linear infinite; /* Adjust speed (increase for slower, decrease for faster) */
  white-space: nowrap; /* Prevent line breaks */
  width: max-content; /* Ensure content extends beyond container */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.animate-move {
  animation: move 2s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(0%, -10px);
    opacity: 0;
  }
  50% {
    transform: translate(0%, 15px);
    opacity: 1;
  }
  100% {
    transform: translate(0%, 25px);
    opacity: 0;
  }
}

@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 100;
  }
}

@supports (-webkit-touch-callout: none) {
  .safari-fix {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .safari-gap-fix {
    row-gap: 20px;
  }
  .safari-tab-fix {
    gap: 40px;
  }
}

.ticker-animation {
  --num: 0; /* Initial Value */
  animation: counter 5s infinite alternate ease-in-out;
  counter-reset: num var(--num);
}

.ticker-animation::after {
  content: counter(num);
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  display: flex;
  animation: marquee 10s linear infinite;
}
 

