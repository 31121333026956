.arrow-container {
  position: relative;
  display: inline-flex;
}

.arrow-icon {
  transition: transform 0.5s ease-in-out;
}

/* On hover, move the arrow slightly to the right */
button:hover .arrow-icon {
  transform: translateX(10px);
}
